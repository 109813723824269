import { ReactElement } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import { Link, useLocation } from 'react-router-dom'
import { resetSimulation } from 'reducers/simulations/slices'
import { NavTabElement } from 'utils/navTabElements'

import './TabElement.scss'

type Props = {
  element: NavTabElement;
  disabled: boolean;
}

export default function TabElement({ element, disabled }: Props): ReactElement {
  const {
    icon, title, path, role,
  } = element

  const dispatch = useAppDispatch()
  const location = useLocation()
  const { webSocket } = useAppSelector(state => state.simulations)
  const { appPermissions } = useAppSelector(state => state.user)
  const isSelected = location.pathname.includes(path)

  const sendMessage = (type: string, pause?) => {
    webSocket.current.send(JSON.stringify({
      messageType: type,
      pause,
    }))
  }

  const handleClick = () => {
    if (webSocket && !disabled) {
      dispatch(resetSimulation())
      sendMessage('STOP')
    }
  }

  const isAllowed = role.length === 0
  || ((appPermissions as string[]).some(permission => role.includes(permission)))

  return isAllowed ? (
    <Link to={path} onClick={handleClick}>
      <div className={`tab-element ${isSelected ? 'selected' : ''}`}>
        {icon && (
        <div className="mr-2">
          <img src={icon} alt="tab icon" />
        </div>
        )}
        <div className="title">
          {title}
        </div>
      </div>
    </Link>
  ) : <div />
}
