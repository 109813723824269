export const savefile = (responseText, filename) => {
  const blob = new Blob([responseText])
  const url = window.URL.createObjectURL(blob)
  const outputFilename = `${filename}.xlsx`
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', outputFilename)
  document.body.appendChild(link)
  link.click()
}
