import { UrlParams } from 'reducers/types'

export const isTcoWindow = () => window.location.href.includes('tco')

export const isTptWindow = () => window.location.href.includes('tpt')

export const isPocSnciWindow = () => window.location.href.includes('poc-snci')

export const isSnciWindow = () => window.location.href.includes('snci')

export const isLogsWindow = () => window.location.href.includes('logs')

export const isOldSimulation = (params: Partial<UrlParams>) => params.mode === 'static'

export const isDependantWindow = () => isSnciWindow() || isTptWindow() || isTcoWindow()
