import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SimulationsState, SvgStream } from 'reducers/types'

export type TcoState = {
  isOpen: boolean;
  stream: SvgStream;
  simulationState: SimulationsState;
  svg: TrustedHTML;
}

const initialState: TcoState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
  svg: undefined,
}

const tcoSlice = createSlice({
  name: 'tco',
  initialState,
  reducers: {
    setTcoOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    updateSimulationState: (state, action: PayloadAction<SimulationsState>) => {
      state.simulationState = action.payload
    },
    setSvg: (state, action: PayloadAction<TrustedHTML>) => {
      state.svg = action.payload
    },
  },
})

export const {
  setTcoOpen,
  updateStream: updateTcoStream,
  updateSimulationState,
  setSvg: setTcoSvg,
} = tcoSlice.actions

export default tcoSlice.reducer
