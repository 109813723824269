import CircleIcon from '@mui/icons-material/Circle'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import moment from 'moment'
import { Comment, Feedbacks } from 'reducers/types'
import { criticityColor } from 'utils'

// todo define column type
const COLUMNS = [
  {
    label: 'ID',
  },
  {
    label: 'Heure',
  },
  {
    label: 'Commentaire',
  },
  {
    label: 'Statut',
  },
  {
    label: 'Criticité',
  },
]

type Props = {
  feedbacks: Feedbacks
  selected: Comment
  handleCheck: (event, comment) => void
}

export default function HistoryTable({
  feedbacks, selected, handleCheck,
}: Props) {
  return (
    <TableContainer sx={{ height: '100%' }}>
      <Table stickyHeader aria-label="history table" size="small">
        <TableHead>
          <TableRow sx={{ 'td, th': { border: 0 } }}>
            <TableCell padding="checkbox" />
            {COLUMNS.map(head => (
              <TableCell key={head.label} sx={{ color: '#A3ADC2', fontSize: 12, paddingY: 0 }}>
                <strong>{head.label}</strong>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {feedbacks.comments.map(comment => (
            <TableRow
              key={comment.id}
              role="button"
              sx={{ 'td, th': { border: 0 }, cursor: 'pointer' }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={comment.id === selected?.id}
                  onChange={e => handleCheck(e, comment)}
                  sx={{ paddingY: 0 }}
                />
              </TableCell>
              <TableCell>
                {comment?.incrementalNumber}
              </TableCell>
              <TableCell>
                {moment(comment?.createdAt).format('HH:mm')}
              </TableCell>
              <TableCell style={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}>
                {comment?.content}
              </TableCell>
              <TableCell>
                {comment?.status}
              </TableCell>
              <TableCell align="center">
                <CircleIcon style={{ color: criticityColor(comment?.severity) }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
