import { useRef, useEffect, useState } from 'react'
import { useAppSelector } from 'hooks'
import IconButton from '@mui/material/IconButton'
import SendSharpIcon from '@mui/icons-material/SendSharp'
import './style.scss'

function MessageInput() {
  const [newMessage, setNewMessage] = useState<string>('')
  const inputRef = useRef<HTMLInputElement | null>(null)
  const { webSocket } = useAppSelector(state => state.simulations)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const sendMessage = (message: string) => {
    if (webSocket.current && message) {
      webSocket.current.send(JSON.stringify({
        messageType: 'COMMAND',
        content: message,
      }))
    }
  }

  const handleSendClick = () => {
    sendMessage(newMessage)
    setNewMessage('')
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSendClick()
    }
  }

  return (
    <div className="message-input">
      <input
        type="text"
        ref={inputRef}
        value={newMessage}
        onChange={e => setNewMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Entrer une commande"
      />
      <IconButton aria-label="send" onClick={() => handleSendClick()} className="icon">
        <SendSharpIcon />
      </IconButton>
    </div>
  )
}

export default MessageInput
