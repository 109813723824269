import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Button, { ButtonStyle } from 'components/button'

type Props = {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  title: string
  confirmBtn: string
}

export default function SimpleModal({
  open, onConfirm, onCancel, title, confirmBtn,
}: Props) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button style={ButtonStyle.light} onClick={onCancel} text="Annuler" />
        <Button onClick={onConfirm} text={confirmBtn} />
      </DialogActions>
    </Dialog>
  )
}
