import { useAppSelector } from 'hooks'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import moment from 'moment'
import './style.scss'

function Clock() {
  const { clockTime } = useAppSelector(state => state.simulations)
  return (
    <div className="clock-time">
      <AccessTimeIcon className="icon" color="primary" />
      <b>{moment(clockTime).format('HH:mm:ss')}</b>
    </div>
  )
}

export default Clock
