import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SimulationsState, SvgStream } from 'reducers/types'

export type SnciState = {
  isOpen: boolean;
  stream: SvgStream;
  simulationState: SimulationsState;
}

const initialState: SnciState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
}

const snciSlice = createSlice({
  name: 'snci',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },

  },
})

export const {
  setOpen: setSnciOpen,
  updateStream: updateSnciStream,
} = snciSlice.actions

export default snciSlice.reducer
