/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import {
  setPocSnciSvg,
} from './slices'

const getSvg = () => createApiThunk(
  'pocSnci/getSvg',
  () => get('/masters/static/interfaces/poc-snci'),
  setPocSnciSvg,
)

export {
  getSvg as getPocSnciSvg,
}
