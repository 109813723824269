export enum FilterOperators {
  equals = 'eq',
  notEquals = 'not_eq',
  lt = 'lt',
  gt = 'gt',
  gte = 'gte',
  lte = 'lte',
  contains = 'contains',
  notContains = 'not_contains',
  notNull = 'notnull',
  null = 'isnull',
  between = 'between',
  in = 'in',
  notin = 'not_in'
}

export type Filter = {
  onField: string;
  operator: FilterOperators;
  value: unknown;
}

export type FilterWithId = Filter & { id: string }
