import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useState } from 'react'

type Props = {
  options: {label: string, value: unknown}[]
  onChange: (val: string[]) => void
  defaultValue: string[]
  disabled: boolean;
}

export default function SelectCheckbox({
  options, onChange, defaultValue, disabled,
}: Props) {
  const [selectedOptions, setSelected] = useState<(string[])>(defaultValue)

  const handleChange = (event: SelectChangeEvent<string[] | number[]>) => {
    const {
      target: { value },
    } = event
    setSelected(value as string[])
  }

  const onClose = () => {
    onChange(selectedOptions)
  }

  return (
    <Select
      disabled={disabled}
      onClose={onClose}
      multiple
      value={selectedOptions}
      onChange={handleChange}
      renderValue={selected => selected.map(opt => options.find(o => o.value === opt)?.label)?.join(', ')}
    >
      {options.map(option => (
        <MenuItem key={option.value as string} value={option.value as string}>
          <Checkbox checked={selectedOptions.indexOf(option.value as string) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  )
}
