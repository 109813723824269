/* eslint-disable jsx-a11y/no-autofocus */
import { modalSignal } from 'components/modals/wrapper'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  useCallback, useEffect, useRef, useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { RootState } from 'reducers/store'
import { updateSimulationState } from 'reducers/tco/slices'
import { UrlParams } from 'reducers/types'
import { BroadcastMessage } from 'types/tco'

import terms from 'assets/localize/terms'
import ErrorModal from 'components/modals/errorModal'
import SimulationStream from 'components/simulationStream'
import { isOldSimulation } from 'services/route'
import { SocketMessage } from 'types/websocket'
import BerreCite from './BerreCite'
import './style.scss'

const MIN_LINES = 10
const MAX_LINES = 20

export default function Snci() {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const { stream } = useAppSelector((state: RootState) => state.snci)
  const [prog, setProg] = useState({})
  const hiddenInput = useRef(null)

  const confirmClose = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const sendCloseMessage = useCallback(() => {
    broadcastRef?.current.postMessage({ type: BroadcastMessage.closeSnci })
  }, [])

  const resizeScrollableDiv = () => {
    const scrollableDiv = document.querySelector('.programmation') as HTMLElement
    const rectangle = document.getElementById('borders')
    const bbox = rectangle.getBoundingClientRect()

    const text = document.getElementsByClassName('text')
    const rect = text[0].getBoundingClientRect()

    // tmp fix firefox not returning the same height than chrome
    if (navigator.userAgent.includes('Firefox')) {
      scrollableDiv.style.fontSize = `${rect.height - 4}px`
    } else {
      scrollableDiv.style.fontSize = `${rect.height}px`
    }

    scrollableDiv.style.width = `${bbox.width}px`
    scrollableDiv.style.height = `${rectangle.getClientRects()[0].top - 72}px`

    if (rectangle.getClientRects()[0].top - 72 < rect.height * MIN_LINES) {
      modalSignal.value = (
        <ErrorModal text={terms.Snci.cantShowLines} />
      )
    } else {
      modalSignal.value = undefined
    }
  }

  useEffect(() => {
    if (!stream) return
    Object.entries(stream).forEach(([key, value]) => {
      if (key.includes('Programmation')) {
        setProg(oldProg => ({ ...oldProg, ...stream }))
      }
      const element = document.getElementById(key)
      const properties = Object.keys(value)
      if (element) {
        properties.forEach(prop => {
          if (prop === 'innerHTML') {
            element.innerHTML = value[prop]
            return
          }
          element.setAttribute(prop, value[prop])
        })
      }
    })
  }, [stream])

  useEffect(() => {
    // initial resize effect to match the size of the upper div with the svg
    // we need to wait for the correct font to load
    document.fonts.ready.then(() => {
      resizeScrollableDiv()
    })
    window.addEventListener('resize', resizeScrollableDiv)

    return () => {
      window.removeEventListener('resize', resizeScrollableDiv)
    }
  }, [])

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.postMessage({ type: BroadcastMessage.openSnci })

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.closeSnci) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateState) {
          dispatch(updateSimulationState(event.data.message))
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(setSelectedSimulation({
            ...selectedSimulation,
            name: event.data.message,
          }))
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id))
    }

    if (isOldSimulation(urlParams)) {
      window.addEventListener('beforeunload', sendCloseMessage)
    } else {
      window.addEventListener('beforeunload', confirmClose)
      window.addEventListener('unload', sendCloseMessage)
    }

    return () => {
      window.removeEventListener('beforeunload', confirmClose)
      window.removeEventListener('unload', sendCloseMessage)
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  useEffect(() => {
    if (isOldSimulation(urlParams)) return

    if (modalSignal.value) {
      window.addEventListener('beforeunload', sendCloseMessage)
      window.removeEventListener('beforeunload', confirmClose)
    } else {
      window.removeEventListener('beforeunload', sendCloseMessage)
      window.addEventListener('beforeunload', confirmClose)
    }
  }, [modalSignal.value])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    webSocket?.current?.send(JSON.stringify({
      messageType: SocketMessage.keyPress,
      key: event.key,
    }))
  }

  // todo dynamic number/name of columns with back
  const createLine = (index: number) => {
    const classes = [
      `ProgrammationLineIndex:${index}`,
      `ProgrammationLineTrainIndex:${index}:0`,
    ]

    const custom = [
      `ProgrammationLine:${index}:0`,
      `ProgrammationLine:${index}:1`,
      `ProgrammationLine:${index}:2`,
      `ProgrammationLine:${index}:3`,
      `ProgrammationLine:${index}:4`,
      `ProgrammationLine:${index}:5`,
      `ProgrammationLine:${index}:6`,
      `ProgrammationLine:${index}:7`,
    ]

    const getColor = (key: string) => prog[key]?.fill || 'white'

    const isBlinking = (key: string) => prog[key]?.blinking

    return (
      <div key={index} className="line">
        {classes.map((className, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            className={isBlinking(className) ? 'blink' : ''}
            style={{ color: getColor(className) }}
          >
            {prog[className]?.innerHTML}
          </div>
        ))}
        {custom.map((className, i) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            <span
              className={isBlinking(`ProgrammationLinePreHook:${index}:${i}`) ? 'blink' : ''}
              style={{ color: getColor(`ProgrammationLinePreHook:${index}:${i}`) }}
            >
              {prog[`ProgrammationLinePreHook:${index}:${i}`]?.innerHTML}
            </span>
            <span
              className={isBlinking(className) ? 'blink' : ''}
              style={{ color: getColor(className) }}
            >
              {prog[className]?.innerHTML}
            </span>
            <span
              className={isBlinking(`ProgrammationLineAfterHook:${index}:${i}`) ? 'blink' : ''}
              style={{ color: getColor(`ProgrammationLinePreHook:${index}:${i}`) }}
            >
              {prog[`ProgrammationLineAfterHook:${index}:${i}`]?.innerHTML}
            </span>
          </div>
        ))}
        <div
          style={{ color: getColor(`ProgrammationLineTrainIndex:${index}:1`) }}
          className={isBlinking(`ProgrammationLineTrainIndex:${index}:1}`) ? 'blink' : ''}
        >
          {prog[`ProgrammationLineTrainIndex:${index}:1`]?.innerHTML}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="snci" onClick={() => hiddenInput?.current?.focus()}>

        <div className="programmation">
          <div className="tca"><div>2-TCA</div></div>
          {Array.from({ length: MAX_LINES }).map((_, index) => createLine(index))}

        </div>

        <div className="svg-commands">

          <BerreCite />
        </div>
        <input
          autoFocus
          ref={hiddenInput}
          onKeyDown={handleKeyPress}
          className="hidden-input"
        />

      </div>

      <SimulationStream type="snci" />
    </>
  )
}
