/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import {
  setTcoSvg,
} from './slices'

const getSvg = (state: 'on' | 'off') => createApiThunk(
  'tpt/getSvg',
  () => get(`/masters/static/interfaces/tco-${state}`),
  setTcoSvg,
)

export {
  getSvg as getTcoSvg,
}
