import ErrorIcon from '@mui/icons-material/Error'
import terms from 'assets/localize/terms'
import { ReactElement } from 'react'
import './style.scss'

type Props = {
  text: string;
}
export default function ErrorModal({ text }: Props): ReactElement {
  return (
    <div className="error-popup">
      <div className="icon">
        <ErrorIcon />
      </div>
      <div className="content-wrapper">

        <div className="header">{text}</div>

        <div className="body">{terms.Snci.pleaseResize}</div>
      </div>

    </div>
  )
}
