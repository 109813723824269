import { useEffect } from 'react'
import { signal } from '@preact/signals-react'

import './style.scss'

/**
 * Signal used to communicate with modals
 * which are responsible for displaying/hiding the modal
*/
export const modalSignal = signal<React.ReactNode>(undefined)

export default function ModalWrapper() {
  const resetSignal = () => {
    modalSignal.value = undefined
  }

  useEffect(() => () => {
    // Reset signal when unmounting modal component
    resetSignal()
  }, [])

  return (
    <div className={`modal-wrapper ${modalSignal.value ? ' displayed' : ''}`}>
      <div className="modal">
        {modalSignal.value}
      </div>
      <div className="background" onClick={resetSignal} />
    </div>
  )
}

ModalWrapper.defaultProps = {
  displayModal: false,
}
