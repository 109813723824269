import React, { MouseEvent, ReactElement, useEffect } from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { isPocSnciWindow } from 'services/route'
import './style.scss'

type Props = {
  svg: TrustedHTML;
  handleClick?: (evt: MouseEvent<HTMLElement>) => void;
  handleKeyPress?: (evt: React.KeyboardEvent) => void
}

export default function SvgWrapper({ svg, handleClick, handleKeyPress }: Props): ReactElement {
  useEffect(() => {
    if (!svg) return

    // tmp fix
    const elementsWithFontFamily = document.querySelectorAll('[font-family]')

    const styleSheet = document.createElement('style')
    document.head.appendChild(styleSheet)
    elementsWithFontFamily.forEach((element, index) => {
      if (element.classList.contains('custom-font-family')) return
      const fontFamily = element.getAttribute('font-family')
      const className = `custom-font-family-${index}`
      element.classList.add(className)

      styleSheet.sheet.insertRule(
        `.${className} { font-family: ${fontFamily} !important }`,
        styleSheet.sheet.cssRules.length,
      )
    })
  }, [svg])

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const inputStyle = {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  }

  return (
    <div className="svg-wrapper">
      {isPocSnciWindow() && (
        <div style={containerStyle}>
          <h3>Should be hidden and always focused: </h3>
          <input onKeyDown={handleKeyPress} style={inputStyle} />
        </div>
      )}
      <TransformWrapper
        wheel={{ smoothStep: 0.01 }}
      >
        <TransformComponent
          wrapperStyle={{ height: '100%', width: '100%' }}
          contentStyle={{ height: '100%', width: '100%' }}
        >
          <div
            onClick={handleClick}
            style={{
              width: '100%',
              height: '100%',
            }}
          // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: svg }}
          />
        </TransformComponent>
      </TransformWrapper>

    </div>
  )
}

SvgWrapper.defaultProps = {
  handleClick: () => undefined,
  handleKeyPress: () => undefined,
}
