import terms from 'assets/localize/terms'
import { speedSignal } from 'components/timeController'
import MAIN_API from 'config/config'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  ReactElement, useEffect, useRef,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { SnackbarSeverity, setSnackbar } from 'reducers/app'
import { updatePocSnciStream } from 'reducers/poc-snci/slices'
import {
  closeWebSocket, setPause, setStarted, setWebSocket, updateMessage, clearLogs,
} from 'reducers/simulations/slices'
import { updateSnciStream } from 'reducers/snci/slices'
import { updateTcoStream } from 'reducers/tco/slices'
import { updateTptStream } from 'reducers/tpt/slices'
import {
  isLogsWindow, isPocSnciWindow, isSnciWindow, isTcoWindow, isTptWindow,
} from 'services/route'
import { LogEntry } from 'types/logs'
import { SocketMessage } from 'types/websocket'

type Props = {
  type: string;
}

export default function SimulationStream({ type }: Props): ReactElement {
  const dispatch = useAppDispatch()
  const { selectedSimulation, webSocket } = useAppSelector(state => state.simulations)
  const [queryParams] = useSearchParams()
  const simulationWasStopped = useRef(false)
  const init = useRef(false)
  const socket = useRef(null)

  const closeSocket = () => {
    if (!isLogsWindow() && !isTcoWindow() && !isTptWindow() && !isPocSnciWindow()) {
      socket?.current?.close()
      dispatch(closeWebSocket())
    }
  }
  useEffect(() => {
    if (selectedSimulation && queryParams.get('mode') !== 'history'
      && !window.location.href.includes('static') && !init.current) {
      init.current = true
      const wsConnect = () => {
        const ws = new WebSocket(
          `${MAIN_API.websocket}/masters/stream/${selectedSimulation?.id}/${type}/?_auth_token=${
            `${localStorage.getItem('access_token')}`}`,
        )
        ws.onopen = () => {
          const connectLogEntry: LogEntry = {
            id: '0',
            logLevel: 'SUCCESS',
            timeStep: '',
            humanTime: '',
            messageType: '',
            content: 'Connexion établie',
            timestamp: '2024-02-13T08:00:00',
          }
          dispatch(updateMessage(connectLogEntry))
        }

        ws.onmessage = (event: MessageEvent) => {
          let responseBatch = JSON.parse(event.data as string)

          // if there's too many messages in one batch,
          // react doesn't seem to update fast enough between redux and useeffect
          if (responseBatch.length > 1) {
            const map = responseBatch.reduce((prev, curr) => {
              if (curr.messageType !== SocketMessage.svg) return prev
              prev = { ...prev, ...curr.patch }
              return prev
            }, {})
            if (isTcoWindow()) {
              dispatch(updateTcoStream(map))
            }
            if (isTptWindow()) {
              dispatch(updateTptStream(map))
            }
            if (isPocSnciWindow()) {
              dispatch(updatePocSnciStream(map))
            }
            if (isSnciWindow()) {
              dispatch(updateSnciStream(map))
            }
            responseBatch = responseBatch.filter(msg => msg.messageType !== SocketMessage.svg)
          }
          responseBatch.forEach(response => {
            if (response.messageType === SocketMessage.start) {
              dispatch(setStarted(response.start))
            }

            if (response.messageType === SocketMessage.svg) {
              if (isTcoWindow()) {
                dispatch(updateTcoStream(response.patch))
                return
              }
              if (isTptWindow()) {
                dispatch(updateTptStream(response.patch))
                return
              }
              if (isPocSnciWindow()) {
                dispatch(updatePocSnciStream(response.patch))
                return
              }
              if (isSnciWindow()) {
                dispatch(updateSnciStream(response.patch))
              }
            }

            if (response.messageType === SocketMessage.pause) {
              dispatch(setPause(response.pause))
            }

            if (response.messageType === SocketMessage.stop) {
              simulationWasStopped.current = true
              webSocket?.current?.close()
              dispatch(closeWebSocket())
            }

            if (response.messageType === SocketMessage.speed) {
              speedSignal.value = response.speed
            }

            if (response.messageType === SocketMessage.clearLogs) {
              dispatch(clearLogs())
            }

            dispatch(updateMessage(response))
          })
        }

        ws.onclose = () => {
          // todo refacto
          if (((isTcoWindow() || isTptWindow() || isPocSnciWindow()) && !window.location.href.includes('static'))
            || isLogsWindow() || isSnciWindow()) {
            dispatch(setSnackbar({ message: terms.Error.webSocket.lostConnection, severity: SnackbarSeverity.WARNING }))
            if (!simulationWasStopped.current) {
              setTimeout(() => {
                const newSocket = wsConnect()
                socket.current = newSocket
                dispatch(setWebSocket({ current: newSocket }))
              }, 1000)
            }
          }

          const connectLogEntry: LogEntry = {
            id: '0',
            logLevel: 'FAIL',
            timeStep: '',
            humanTime: '',
            messageType: '',
            content: 'Connexion perdue',
            timestamp: '2024-02-13T08:00:00',
          }
          dispatch(updateMessage(connectLogEntry))
        }
        return ws
      }

      const wsCurrent = wsConnect()
      socket.current = wsCurrent

      dispatch(setWebSocket({ current: wsCurrent }))

      return () => {
        closeSocket()
      }
    }

    return () => {
      closeSocket()
    }
  }, [selectedSimulation])

  return (
    <> </>
  )
}
