import { ReactElement } from 'react'

const LINE_COLOR = '#D9D9D9'
export default function BerreCite(): ReactElement {
  return (
    <svg
      className="container"
      viewBox="0 0 1407 840"
    >
      <rect id="borders" x="0" y="420" width="1406" height="849" fill="black" />
      <g>

        <g id="Rappels">
          <text
            className="text"
          >
            <tspan x="852" y="621">Rappels</tspan>
          </text>
          <text
            className="overflow"
          >
            <tspan x="685" y="630" id="ReminderLight" />
          </text>
          <text
            className="text"
          >
            <tspan x="695" y="651" id="Reminders:0" />
          </text>
          <text
            className="text"
          >
            <tspan x="695" y="677" id="Reminders:1" />
          </text>
          <text
            className="text"
          >
            <tspan x="695" y="703" id="Reminders:2" />
          </text>
          <text
            className="text"
          >
            <tspan x="695" y="729" id="Reminders:3" />
          </text>

          <text
            className="text"
          >
            <tspan x="920" y="651" id="Reminders:4" />
          </text>
          <text
            className="text"

          >
            <tspan x="920" y="677" id="Reminders:5" />
          </text>
          <text
            className="text"
          >
            <tspan x="920" y="703" id="Reminders:6" />
          </text>
          <text
            className="text"
          >
            <tspan x="920" y="729" id="Reminders:7" />
          </text>
        </g>

        <text
          className="text"
        >
          <tspan
            x="691"
            y="758"
          >
            Protections :
          </tspan>
        </text>

        <text
          className="text"
        >
          <tspan
            id="ProtectionCount"
            x="885"
            y="755"
          />
        </text>

        <text
          id="DIN :"
          className="text"
        >
          <tspan x="742" y="594">DIN :</tspan>
        </text>
        <text
          id="01"
          className="text"
        >
          <tspan x="592" y="795">01</tspan>
        </text>
        <text
          id="PR/S"
          className="text"
          letterSpacing="0em"
        >
          <tspan x="837" y="795">PR/S</tspan>
        </text>
        <text
          id="PR/F"
          className="text"
        >
          <tspan x="993" y="795">PR/F</tspan>
        </text>
        <text
          id="A"
          className="text"
        >
          <tspan x="925" y="795">A</tspan>
        </text>
        <text
          id="NA"
          className="text"
        >
          <tspan x="1081" y="795">NA</tspan>
        </text>

        <text
          id="Z :"
          className="text"
        >
          <tspan x="605" y="543">Z :</tspan>
        </text>
        <text
          className="text"
        >
          <tspan x="655" y="543" id="TestZones:0" />
        </text>
        <text
          className="text"
        >
          <tspan x="680" y="543" id="TestZones:1" />
        </text>
        <text
          className="text"
        >
          <tspan x="705" y="543" id="TestZones:2" />
        </text>
        <text
          className="text"
        >
          <tspan x="730" y="543" id="TestZones:3" />
        </text>

        <text
          className="text"
        >
          <tspan x="310" y="543">AG :</tspan>
        </text>
        <text
          className="text"
        >
          <tspan x="375" y="543" id="TestSwitches:0" />
        </text>
        <text
          className="text"
        >
          <tspan x="398" y="543" id="TestSwitches:1" />
        </text>
        <text
          className="text"
        >
          <tspan x="421" y="543" id="TestSwitches:2" />
        </text>
        <text
          className="text"
        >
          <tspan x="444" y="543" id="TestSwitches:3" />
        </text>
        <text
          className="text"
          fontSize="16"
        >
          <tspan id="Input" x="20" y="832.75" />
        </text>
        <text
          fill="white"
          className="text"
          fontSize="16"
        >
          <tspan id="DialogueError" x="20" y="796.75" />
        </text>
        <text
          id="MENU"
          className="text"
        >
          <tspan x="178" y="621">MENU</tspan>
        </text>
        <g id="Menu:col:0">
          <text
            className="text"
          >
            <tspan id="Menu:col:0:row:0" x="22" y="650" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:0:row:1" x="22" y="674" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:0:row:2" x="22" y="698" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:0:row:3" x="22" y="722" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:0:row:4" x="22" y="746" />
          </text>
        </g>

        <g id="Menu:col:1">
          <text
            className="text"
          >
            <tspan id="Menu:col:1:row:0" x="232" y="650" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:1:row:1" x="232" y="674" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:1:row:2" x="232" y="698" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:1:row:3" x="232" y="722" />
          </text>
          <text
            className="text"
          >
            <tspan id="Menu:col:1:row:4" x="232" y="746" />
          </text>
        </g>
        <text
          id="Dec :"
          className="text"
        >
          <tspan x="293" y="594">Dec :</tspan>
        </text>
        <text
          className="text"
        >
          <tspan
            id="Decondemnation"
            x="370"
            y="594"
          />
        </text>
        <text
          id="It en Cde"
          className="text"
        >
          <tspan x="490" y="621">It en Cde</tspan>
        </text>
        <text
          id="VG :"
          className="text"
        >
          <tspan x="20" y="543">VG :</tspan>
        </text>
        <text
          className="text"
        >
          <tspan x="20" y="593" id="Preparation" />
        </text>
        <text
          id="Def"
          className="text"
        >
          <tspan x="1122" y="724">Def</tspan>
        </text>

        <text
          id="Alarmes"
          className="text"
        >
          <tspan x="1219" y="570">Alarmes</tspan>
        </text>
        <text
          id="RoutelessRequest"
          className="text"
        >
          <tspan x="1215" y="776">Dem. sans IT</tspan>
        </text>
        <text
          id="Index"
          className="text"
        >
          <tspan x="1122" y="750">Index</tspan>
        </text>

        <rect id="vg-right-border" x="294" y="511" width="2" height="49" fill={LINE_COLOR} />

        <rect id="preparation-right-border" x="277" y="562" width="2" height="49" fill={LINE_COLOR} />
        <rect id="menu-right-border" x="415" y="611" width="2" height="155" fill={LINE_COLOR} />
        <rect id="it-right-border" x="675" y="611" width="2" height="155" fill={LINE_COLOR} />
        <rect id="reminder-right-border" x="1106" y="611" width="2" height="155" fill={LINE_COLOR} />
        <rect id="dec-right-border" x="726" y="562" width="2" height="49" fill={LINE_COLOR} />
        <rect id="din-right-border" x="1106" y="562" width="2" height="49" fill={LINE_COLOR} />
        <rect id="ag-right-border" x="589" y="511" width="2" height="49" fill={LINE_COLOR} />
        <rect id="error-right-border" x="571" y="767" width="2" height="35" fill={LINE_COLOR} />
        <rect id="01-right-border" x="639" y="767" width="2" height="35" fill={LINE_COLOR} />
        <rect id="empty-right-border" x="811" y="767" width="2" height="35" fill={LINE_COLOR} />
        <rect id="prs-right-border" x="967" y="767" width="2" height="35" fill={LINE_COLOR} />
        <rect id="prf-right-border" x="1156" y="767" width="2" height="75" fill={LINE_COLOR} />
        <rect id="z-right-border" x="864" y="511" width="2" height="49" fill={LINE_COLOR} />
        <rect id="z-empty-right-border" x="1071" y="511" width="2" height="49" fill={LINE_COLOR} />

        {/* horizontal lines */}
        <rect id="first-line" y="432" width="1407" height="2" fill={LINE_COLOR} />
        <rect id="second-line" y="509" width="1407" height="2" fill={LINE_COLOR} />
        <rect id="def-line" x="1176" y="714" width="230" height="2" fill={LINE_COLOR} />
        <path
          id="third-line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1211 560H0V562H1206V560ZM1313 562H1407V560H1315V562Z"
          fill={LINE_COLOR}
        />
        <path
          id="fourth-line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M833 611H610V613H840V611ZM940 613H1107V611H945V613ZM239 611H480V613H239V611ZM167 611H0V613H167V611Z"
          fill={LINE_COLOR}
        />

        <path
          id="last-line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1203 765H0V767H1200V765ZM1369 767H1370V765H1400V767Z"
          fill={LINE_COLOR}
        />

        <text
          className="text"
        >
          <tspan id="Date" x="1087" y="543" />
          <tspan id="Clock" x="1243" y="543" />
        </text>
        <text
          id="*_2"
          className="overflow"
        >
          <tspan x="1164" y="789" id="PassageRequestWithoutRouteLight" />
        </text>
        <g id="Alarms">
          <text
            className="text"
          >
            <tspan id="Alarms:0" x="1126" y="598" />
          </text>
          <text
            className="text"
          >
            <tspan id="Alarms:1" x="1126" y="622" />
          </text>
          <text
            className="text"
          >
            <tspan id="Alarms:2" x="1126" y="646" />
          </text>
          <text
            className="text"
          >
            <tspan id="Alarms:3" x="1126" y="670" />
          </text>
          <text
            className="text"
          >
            <tspan id="Alarms:4" x="1126" y="694" />
          </text>
        </g>
        <text
          className="text"
        >
          <tspan id="DefaultIndex" x="1230" y="750" />
        </text>
        <g id="OrderedRoutes">
          <text
            className="overflow"
          >
            <tspan id="OrderedRouteLight" x="425" y="635" />
          </text>
          <text
            className="text"
          >
            <tspan id="OrderedRoutes:0" x="437" y="651" />
          </text>
          <text
            className="text"
          >
            <tspan id="OrderedRoutes:1" x="437" y="677" />
          </text>
          <text
            className="text"
          >
            <tspan id="OrderedRoutes:2" x="437" y="703" />
          </text>
          <text
            className="text"
          >
            <tspan id="OrderedRoutes:3" x="437" y="729" />
          </text>
          <text
            className="text"
          >
            <tspan id="OrderedRoutes:4" x="437" y="755" />
          </text>
        </g>
        <text
          className="text"
        >
          <tspan id="PassageRequestWithoutRoute:0" x="1239" y="810" />
        </text>
        <text
          className="text"
        >
          <tspan id="PassageRequestWithoutRoute:1" x="1239" y="834" />
        </text>
      </g>
    </svg>
  )
}
