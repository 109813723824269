import SvgWrapper from 'components/SvgWrapper'
import SimulationStream from 'components/simulationStream'
import { useAppDispatch, useAppSelector } from 'hooks'
import React, {
  MouseEvent, ReactElement, useEffect, useRef,
} from 'react'
import { useParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { RootState } from 'reducers/store'
import { updateSimulationState } from 'reducers/poc-snci/slices'
import { getPocSnciSvg } from 'reducers/poc-snci/thunks'
import { UrlParams } from 'reducers/types'
import { isOldSimulation } from 'services/route'
import { BroadcastMessage } from 'types/tco'
import { SocketMessage } from 'types/websocket'

export default function PocSnci(): ReactElement {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const { stream, svg } = useAppSelector((state: RootState) => state.pocSnci)

  const sendCloseMessage = () => {
    broadcastRef?.current.postMessage({ type: BroadcastMessage.closePocSnci })
  }

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.closePocSnci) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateState) {
          dispatch(updateSimulationState(event.data.message))
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(setSelectedSimulation({
            ...selectedSimulation,
            name: event.data.message,
          }))
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id))
    }

    window.addEventListener('beforeunload', sendCloseMessage)

    return () => {
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  const handleKeyPress = (event: React.KeyboardEvent) => {
    webSocket.current.send(JSON.stringify({
      messageType: SocketMessage.keyPress,
      key: event.key,
    }))
  }

  const pressEvent = handleKeyPress

  useEffect(() => {
    dispatch(getPocSnciSvg())
  }, [])

  useEffect(() => {
    console.log('stream', stream)
    console.log('svg', svg)
    if (!stream || !svg) return
    Object.entries(stream).forEach(([key, value]) => {
      console.log(key)
      const element = document.getElementById(key)
      const properties = Object.keys(value)
      console.log(properties)
      if (element) {
        properties.forEach(prop => {
          if (prop === 'innerHTML') {
            element.innerHTML = value[prop]
            return
          }
          element.setAttribute(prop, value[prop])
        })
      }
    })
  }, [stream, svg])

  return (
    <>
      <SvgWrapper svg={svg} handleKeyPress={pressEvent} />
      <SimulationStream type="poc-snci" />
    </>
  )
}
