export enum BroadcastMessage {
  closeTco = 'close_tco',
  openTco = 'open_tco',
  closeTpt = 'close_tpt',
  openTpt = 'open_tpt',
  closePocSnci = 'close_poc_snci',
  closeSnci = 'close_snci',
  openSnci = 'open_snci',
  updateName = 'update_name',
  updateState = 'update_state'
}
