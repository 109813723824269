export const COLUMNS = [
  {
    label: 'N°',
    sortBy: 'incrementalNumber',
    width: 50,
  },
  {
    label: 'CP',
    sortBy: 'userId',
    width: 120,
  },
  {
    label: 'Titre',
    sortBy: 'name',
    width: 280,
  },
  {
    label: 'Criticité',
    sortBy: 'severity',
    width: 100,
  },
  {
    label: 'Statut',
    sortBy: 'status',
    width: 170,
  },
  {
    label: 'Date',
    sortBy: 'createdAt',
    width: 180,
  },
  {
    label: 'Version',
    sortBy: 'versionName',
    width: 280,
  },
  {
    label: '',
    sortBy: '',
  },
]
