import { TextField } from '@mui/material'
import {
  ChangeEvent,
  ReactElement,
  useEffect, useState,
} from 'react'
import './style.scss'

type Props = {
  value: string;
  onChange: (val: string) => void
  disabled: boolean;

}
export default function TextInput({
  value, onChange, disabled,
}: Props): ReactElement {
  const [input, setInput] = useState('')

  useEffect(() => {
    setInput(value)
  }, [value])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }

  return (
    <TextField
      disabled={disabled}
      className="input-text"
      value={input}
      onChange={handleChange}
    />

  )
}
