import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MutableRefObject } from 'react'
import {
  Feedback,
  Feedbacks,
  Network,
  PaginatedResponse,
  Schedule,
  Severity,
  Simulation,
  Status,
  Version,
} from 'reducers/types'
import { Filter } from 'types/filters'
import { LogEntry } from 'types/logs'
import { SocketMessage } from 'types/websocket'

export type SimulationsState = {
  versions: Version[]
  selectedVersion: string;
  networks: Network[];
  schedules: Schedule[];
  selectedSimulation: Simulation;
  webSocket: MutableRefObject<WebSocket>;
  messages: LogEntry[];
  feedbacks: Feedbacks;
  feedback: Feedback;
  clockTime: string;
  displayComments: boolean;
  simulationsList: PaginatedResponse<Simulation>;
  feedbacksCheckboxes: string[];
  feedbackSuccess: boolean;
  commentStatusList: Status[];
  commentSeverityList: Severity[];
  fileData: ArrayBuffer | undefined;
  broadcast: MutableRefObject<BroadcastChannel>;
  paused: boolean;
  filters: Filter[];
  started: boolean;
}

const initialState: SimulationsState = {
  versions: [],
  selectedVersion: undefined,
  networks: [],
  schedules: [],
  selectedSimulation: undefined,
  webSocket: undefined,
  messages: [],
  feedbacks: undefined,
  feedback: undefined,
  clockTime: '2024-02-13T08:00:00',
  displayComments: false,
  simulationsList: {
    count: 0, next: null, previous: null, results: [],
  },
  feedbacksCheckboxes: [],
  feedbackSuccess: false,
  commentStatusList: [],
  commentSeverityList: [],
  fileData: undefined,
  broadcast: undefined,
  paused: false,
  filters: [],
  started: false,
}

const simulationsSlice = createSlice({
  name: 'simulations',
  initialState,
  reducers: {
    resetSimulation: state => {
      state.webSocket = undefined
      state.messages = []
      state.feedbacksCheckboxes = []
      state.clockTime = '2024-02-13T08:00:00'
      state.selectedSimulation = undefined
    },
    resetFileData: state => {
      state.fileData = null
    },
    resetFeedbacksCheckboxes: state => {
      state.feedbacksCheckboxes = []
    },
    updateFeedbacksCheckboxes(state, action: PayloadAction<string>) {
      if (!state.feedbacksCheckboxes.includes(action.payload)) {
        state.feedbacksCheckboxes.push(action.payload)
      } else {
        state.feedbacksCheckboxes.splice(state.feedbacksCheckboxes.indexOf(action.payload), 1)
      }
    },
    updateHistoryCommentsCheckboxes(state, action: PayloadAction<string[]>) {
      state.feedbacksCheckboxes = action.payload
    },
    setVersions(state, action: PayloadAction<Version[]>) {
      state.versions = action.payload
    },
    setSelectedVersion(state, action: PayloadAction<string>) {
      state.selectedVersion = action.payload
    },
    setNetworks(state, action: PayloadAction<Network[]>) {
      state.networks = action.payload
    },
    setSchedules(state, action: PayloadAction<Schedule[]>) {
      state.schedules = action.payload
    },
    setSelectedSimulation(state, action: PayloadAction<Simulation>) {
      state.selectedSimulation = action.payload
    },
    toggleDisplayComments: (state, action: PayloadAction<boolean>) => {
      state.displayComments = action.payload
    },
    setWebSocket: (state, action: PayloadAction<MutableRefObject<WebSocket>>) => {
      state.webSocket = action.payload
    },
    closeWebSocket: state => {
      if (state.webSocket?.current) {
        state.webSocket?.current.close()
        state.webSocket = undefined
      }
    },
    setFeedbacks: (state, action: PayloadAction<Feedbacks>) => {
      state.feedbacks = action.payload
    },
    setFeedback: (state, action: PayloadAction<Feedback>) => {
      state.feedback = action.payload
      state.feedbacksCheckboxes = []
    },
    setFeedbackSuccess: (state, action: PayloadAction<boolean>) => {
      state.feedbackSuccess = action.payload
    },
    setSimulationsList: (state, action: PayloadAction<PaginatedResponse<Simulation>>) => {
      state.simulationsList = action.payload
    },
    setCommentStatusList: (state, action: PayloadAction<Status[]>) => {
      state.commentStatusList = action.payload
    },
    setCommentSeverityList: (state, action: PayloadAction<Severity[]>) => {
      state.commentSeverityList = action.payload
    },
    setFileData: (state, action: PayloadAction<ArrayBuffer | undefined>) => {
      state.fileData = action.payload
    },
    updateMessage: (state, action: PayloadAction<LogEntry>) => {
      state.messages.push(action.payload)
      if (action.payload.messageType === SocketMessage.time) {
        const content = action.payload.timestamp
        state.clockTime = content
      }
    },
    clearLogs: state => {
      state.messages = []
    },
    setBroadcast: (state, action: PayloadAction<MutableRefObject<BroadcastChannel>>) => {
      state.broadcast = action.payload
    },
    setPause: (state, action: PayloadAction<boolean>) => {
      state.paused = action.payload
    },
    setStarted: (state, action: PayloadAction<boolean>) => {
      state.started = action.payload
    },
    setFilters: (state, action: PayloadAction<Filter[]>) => {
      state.filters = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase('simulations/patchFeedback/fulfilled', state => {
      state.feedbackSuccess = true
    })
    builder.addCase('simulations/postFeedback/fulfilled', state => {
      state.feedbackSuccess = true
    })
  },
})

export const {
  setVersions,
  setSelectedVersion,
  setNetworks,
  setSchedules,
  setSelectedSimulation,
  setWebSocket,
  updateMessage,
  clearLogs,
  toggleDisplayComments,
  resetSimulation,
  setFeedbacks,
  setFeedback,
  setSimulationsList,
  updateFeedbacksCheckboxes,
  resetFeedbacksCheckboxes,
  updateHistoryCommentsCheckboxes,
  setFeedbackSuccess,
  setCommentStatusList,
  setCommentSeverityList,
  closeWebSocket,
  setFileData,
  resetFileData,
  setBroadcast,
  setPause,
  setFilters,
  setStarted,
} = simulationsSlice.actions

export default simulationsSlice.reducer
