import DeleteIcon from '@mui/icons-material/Delete'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Badge, IconButton } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import terms from 'assets/localize/terms'
import SimpleButton, { ButtonStyle } from 'components/button'
import Filters, { FilterSignal } from 'components/filters'
import Header from 'components/header'
import SimpleModal from 'components/modals/simpleModal'
import { useAppDispatch, useAppSelector } from 'hooks'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  resetFileData,
} from 'reducers/simulations/slices'
import {
  deleteSimulations,
  exportSimulations,
  getCommentSeverityList,
  getCommentStatusList,
  getSimulationsList, getSimulationsUsers, getVersions,
} from 'reducers/simulations/thunks'
import { Simulation } from 'reducers/types'
import { sanitizeFilters } from 'services/filter'
import { FilterOperators, FilterWithId } from 'types/filters'
import { savefile } from 'utils'
import BasicTable from './table/table'

import './style.scss'

export default function History() {
  const dispatch = useAppDispatch()
  const {
    versions, fileData, filters, simulationsList,
  } = useAppSelector(state => state.simulations)
  const [openModal, setOpenModal] = useState(false)
  const [versionSelected, setVersionSelected] = useState('all')
  const [order, setOrder] = useState<string>('-')
  const [orderBy, setOrderBy] = useState<string>('createdAt')
  const [selectedSimulations, setSelectedSimulations] = useState<string[]>([])
  const [pageSize, setPageSize] = useState(50)
  const [page, setPage] = useState(0)

  useEffect(() => {
    dispatch(getCommentStatusList())
    dispatch(getCommentSeverityList())
    dispatch(getVersions())
    dispatch(getSimulationsUsers())
  }, [])

  useEffect(() => {
    if (openModal) return

    dispatch(getSimulationsList(
      {
        versionId: versionSelected === 'all' ? null : versionSelected,
        ordering: order.concat(orderBy),
        page_size: pageSize,
        page: page + 1,
        filters: sanitizeFilters(filters as FilterWithId[]),
      },
    ))
  }, [versions, order, versionSelected, openModal, pageSize, page])

  useEffect(() => {
    if (filters.some(filter => (filter.value === '')
      && (filter.operator !== FilterOperators.null && filter.operator !== FilterOperators.notNull))) return

    dispatch(getSimulationsList(
      {
        versionId: versionSelected === 'all' ? null : versionSelected,
        ordering: order.concat(orderBy),
        page_size: pageSize,
        page: 1,
        filters: sanitizeFilters(filters as FilterWithId[]),
      },
    ))
  }, [filters])

  const handleChange = (event: SelectChangeEvent) => {
    setVersionSelected(event.target.value)
  }

  const handleSelectedSimulations = (ids: string[]) => {
    setSelectedSimulations(ids)
  }

  useEffect(() => {
    if (fileData) {
      savefile(fileData, moment().format('DD/MM/YYYY'))
    }
    dispatch(resetFileData())
  }, [fileData])

  const handleExport = () => {
    dispatch(exportSimulations(selectedSimulations))
  }

  const handleClickSimulation = (simulation: Simulation) => {
    window.open(`${window.location.origin}/logs/${simulation.id}?mode=history`)
  }

  const handleConfirmModal = () => {
    let deleteFilters = []
    const isDeletingAll = selectedSimulations.length === simulationsList.count

    if (isDeletingAll) {
      deleteFilters = filters
    } else {
      deleteFilters = [{
        operator: FilterOperators.in,
        onField: 'id',
        value: selectedSimulations,
      }]
    }
    dispatch(deleteSimulations({
      filters: deleteFilters,
    })).then(res => {
      if (res.type.includes('fulfilled')) {
        setOpenModal(false)
        setSelectedSimulations([])
      }
    })
  }

  const handleDeleteSimulations = () => {
    setOpenModal(true)
  }

  return (
    <div className="history">
      <Header />
      {FilterSignal.value && (<Filters />)}

      <div className="wrapper d-flex flex-column">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div style={{ fontSize: 24 }}><strong>Choisissez une simulation</strong></div>
            <FormControl sx={{ m: 1, minWidth: 256, maxWidth: 350 }} size="small">
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={versionSelected}
                onChange={handleChange}
                sx={{ background: 'white' }}
              >
                <MenuItem value="all">Toutes les versions</MenuItem>
                {versions.length > 0 && versions.map(version => (
                  <MenuItem key={version?.id} value={version?.id}>{version?.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="buttons">
            <IconButton disabled={!selectedSimulations.length} onClick={handleDeleteSimulations}>
              <DeleteIcon />
            </IconButton>
            <Badge badgeContent={filters.length}>
              <SimpleButton
                text="Filtres"
                style={ButtonStyle.primary}
                startIcon={<FilterAltIcon />}
                onClick={() => { FilterSignal.value = true }}
              />
            </Badge>
            <SimpleButton
              text="Exporter vers Excel"
              style={ButtonStyle.primary}
              startIcon={<UploadFileIcon />}
              onClick={() => handleExport()}
              disabled={selectedSimulations.length === 0}
            />

          </div>
        </div>
        <BasicTable
          setOrder={setOrder}
          order={order}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          onClick={handleClickSimulation}
          onSelectSimulations={handleSelectedSimulations}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
        />
      </div>
      <SimpleModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={handleConfirmModal}
        title={terms.History.confirmDeletion(selectedSimulations.length)}
        confirmBtn="Supprimer"
      />
    </div>
  )
}
