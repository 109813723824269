import { useAppDispatch, useAppSelector } from 'hooks'
import { useRef } from 'react'
import { setFeedbackSuccess } from 'reducers/simulations/slices'
import { Comment } from 'reducers/types'
import Alert from '@mui/material/Alert'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import SendSharpIcon from '@mui/icons-material/SendSharp'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import terms from 'assets/localize/terms'

type Props = {
  handleChangeMessage: (message) => void
  handlePostFeedbacks: () => void
  setStatus: React.Dispatch<React.SetStateAction<string>>
  setSeverity: React.Dispatch<React.SetStateAction<string>>
  newMessage: string
  selected: Comment
  status: string
  severity: string
}

export default function CommentForm({
  handleChangeMessage, selected, setStatus, setSeverity, newMessage, status, severity,
  handlePostFeedbacks,
}: Props) {
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const {
    feedbackSuccess, feedbacksCheckboxes, commentStatusList, commentSeverityList,
  } = useAppSelector(state => state.simulations)

  return (
    <Card variant="outlined">
      <CardContent sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        <strong>{terms.Comment.comments}</strong>
        <Snackbar
          open={feedbackSuccess}
          sx={{ display: 'block', position: 'static', boxShadow: 'none' }}
          autoHideDuration={4000}
          message="This Snackbar will be dismissed in 5 seconds."
          onClose={() => dispatch(setFeedbackSuccess(false))}
        >
          <Alert
            severity="success"
            variant="filled"
            icon={<CheckBoxIcon fontSize="large" />}
            sx={{ width: '100%', display: 'flex', alignItems: 'center' }}
          >
            <strong style={{ fontSize: 18 }}>{selected ? 'Commentaire modifié !' : 'Commentaire envoyé !'}</strong>
            <br />
            {terms.Comment.findInHistory}
          </Alert>
        </Snackbar>
        {!selected && (
          <div style={{
            paddingTop: 24,
            paddingBottom: 24,
            textAlign: 'center',
            background: '#F5F9FF',
          }}
          >
            {feedbacksCheckboxes.length > 0 ? (
              <span>
                {feedbacksCheckboxes.length}
                {' '}
                {terms.Comment.selectedLogs}
              </span>
            ) : (
              <>
                {terms.Comment.writeGeneral}
                <br />
                {terms.Common.or}
                <br />
                {terms.Comment.selectLogs}
              </>
            )}

          </div>
        )}
        <Box display="flex" gap={2}>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={severity}
              displayEmpty
              onChange={e => setSeverity(e.target.value as string)}
            >
              {' '}
              <MenuItem value="">
                <em>{terms.Comment.criticity}</em>
              </MenuItem>
              {commentSeverityList.filter(sev => sev.level).map(sev => (
                <MenuItem key={sev.content} value={sev.content}>{sev.content}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={status}
              displayEmpty
              onChange={e => setStatus(e.target.value as string)}
            >
              <MenuItem value="">
                <em>Statut</em>
              </MenuItem>
              {commentStatusList.filter(stat => stat.level).map(stat => (
                <MenuItem key={stat.content} value={stat.content}>{stat.content}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <div className="comment-input">
          <textarea
            ref={inputRef}
            value={selected ? selected.content : newMessage}
            onChange={e => handleChangeMessage(e.target.value)}
            placeholder={terms.Comment.writeOne}
            rows={2}
            style={{ height: 'auto', minHeight: '50px' }}
          />
          <IconButton
            aria-label="send"
            onClick={() => handlePostFeedbacks()}
            className="icon"
            disabled={newMessage === '' || status === '' || severity === ''}
          >
            <SendSharpIcon />
          </IconButton>
        </div>
      </CardContent>
    </Card>
  )
}
