import { Close } from '@mui/icons-material'
import terms from 'assets/localize/terms'
import DatePicker from 'components/DatePicker/DatePicker'
import moment, { Moment } from 'moment'
import { useState } from 'react'
import './DateRangeFilter.scss'
import { getDateRange } from './utils'

type Props = {
  onChange: (dates: Moment[]) => void;
  defaultValue: Moment[];
}

function DateRangeFilter({ onChange, defaultValue }: Props) {
  const [expanded, setExpanded] = useState(false)
  const [startDate, setStartDate] = useState<Moment>(defaultValue[0] || moment(new Date()))
  const [endDate, setEndDate] = useState<Moment>(defaultValue[1] || moment(new Date()))

  const handleDateChange = (newStartDate: Moment, newEndDate: Moment) => {
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  const handleCancel = () => {
    setExpanded(false)
  }

  const handleButtonClick = () => {
    if (expanded) {
      handleCancel()
    } else {
      setExpanded(true)
    }
  }

  const handleValidate = () => {
    if (!startDate || !endDate) return
    onChange(([startDate, endDate]))
    // onchange event
    setExpanded(false)
  }

  const handleClear = () => {
    handleDateChange(moment(new Date()), moment(new Date()))
  }

  return (
    <>
      <button className="range-date" type="button" onClick={handleButtonClick}>
        <span>
          {defaultValue.length ? getDateRange(startDate, endDate) : ''}
        </span>
      </button>
      {expanded && (
        <div className="date-range-filter">
          <button type="button" className="close" onClick={handleCancel}>
            <Close />
          </button>
          <div className="pickers">
            <DatePicker
              startDate={startDate}
              onChange={handleDateChange}
              endDate={endDate}
            />
          </div>
          <div className="actions">
            <button type="button" onClick={handleClear}>
              {terms.Common.cancel}
            </button>
            <button type="button" onClick={handleValidate} disabled={!startDate || !endDate}>
              {terms.Common.validate}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default DateRangeFilter
