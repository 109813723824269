export interface NavTabElement {
  icon?: string,
  title: string,
  path: string,
  role: string[],
}

const navTabElements: NavTabElement[] = [
  {
    title: 'Nouvelle simulation',
    path: '/',
    role: [],
  },
  {
    title: 'Historique',
    path: '/history',
    role: [],
  },
]

const tcoTabElement = (name: string, uri: string) => [
  {
    title: name,
    path: uri,
    role: [],
  },
]

export { navTabElements, tcoTabElement }
