import { Checkboxs, CheckedItems } from 'types/miscs'
import SimpleButton, { ButtonStyle } from 'components/button'
import Checkbox from '@mui/material/Checkbox'
import CheckIcon from '@mui/icons-material/Check'

import './style.scss'

type Props = {
  checkedItems: CheckedItems,
  setCheckedItems: React.Dispatch<React.SetStateAction<CheckedItems>>
}

function CheckBox({ checkedItems, setCheckedItems }: Props) {
  const checkboxes: Checkboxs[] = [
    { type: 'info', label: 'Information', width: 145 },
    { type: 'debug', label: 'Debug', width: 110 },
    { type: 'error', label: 'Erreur', width: 110 },
    { type: 'system', label: 'Système', width: 120 },
  ]

  const handleCheckboxChange = (type: keyof CheckedItems) => {
    setCheckedItems((prevState: CheckedItems) => ({
      ...prevState,
      [type]: !prevState[type],
    }))
  }

  return (
    <div className="checkboxes">
      <div className="wrapper">
        Filtrer :
        {checkboxes.map(({ type, label, width }: Checkboxs) => (
          <Checkbox
            key={type}
            checked={checkedItems[`${type}Checked`]}
            onChange={() => handleCheckboxChange(`${type}Checked`)}
            inputProps={{ 'aria-label': label }}
            icon={<SimpleButton text={label} style={ButtonStyle.light} sx={{ minWidth: width }} />}
            checkedIcon={<SimpleButton text={label} startIcon={<CheckIcon />} sx={{ minWidth: width }} />}
          />
        ))}
      </div>
    </div>
  )
}

export default CheckBox
