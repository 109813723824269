import { ReactElement } from 'react'
import { NavTabElement } from 'utils/navTabElements'
import TabElement from './TabElement'
import './TabList.scss'

type Props = {
  tabs: NavTabElement[];
  isDependantWindow: boolean;
}

const style = {
  flex: 1,
}

export default function TabList({ tabs, isDependantWindow }: Props): ReactElement {
  return (
    <nav className="tab-list">
      <ul className="d-flex align-item-center h-100 ml-3">
        <li className="d-flex align-item-center h-100 ml-3" style={style}>
          {tabs.map(el => (
            <TabElement key={el.title} element={el} disabled={isDependantWindow} />
          ))}
        </li>
      </ul>
    </nav>
  )
}
