export enum Criticity {
  minor = 'mineure',
  major = 'majeure',
  blocking = 'bloquant'
}

export const criticityColor = (severity: string) => {
  switch (severity) {
    case Criticity.minor:
      return '#43CF7B'
    case Criticity.major:
      return '#FAB142'
    case Criticity.blocking:
      return '#DA4238'
    default:
      return 'lightgrey'
  }
}
