import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SimulationsState, SvgStream } from 'reducers/types'

export type TptState = {
  isOpen: boolean;
  stream: SvgStream,
  simulationState: SimulationsState;
  svg: TrustedHTML;
}

const initialState: TptState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
  svg: undefined,
}

const tptSlice = createSlice({
  name: 'tpt',
  initialState,
  reducers: {
    setTptOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    updateSimulationState: (state, action: PayloadAction<SimulationsState>) => {
      state.simulationState = action.payload
    },
    setSvg: (state, action: PayloadAction<TrustedHTML>) => {
      state.svg = action.payload
    },
  },
})

export const {
  setTptOpen,
  updateStream: updateTptStream,
  updateSimulationState,
  setSvg: setTptSvg,
} = tptSlice.actions

export default tptSlice.reducer
