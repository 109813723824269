import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { userInfo } from 'reducers/types'

export type UserInfoState = {
  usersInfo: userInfo[]
}

const initialState: UserInfoState = {
  usersInfo: [],
}

const userInfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    setUsersInfo: (state, action: PayloadAction<userInfo[]>) => {
      state.usersInfo = action.payload
    },
  },
})

export const {
  setUsersInfo,
} = userInfoSlice.actions

export default userInfoSlice.reducer
