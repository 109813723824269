import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SimulationsState, SvgStream } from 'reducers/types'

export type PocSnciState = {
  isOpen: boolean;
  stream: SvgStream,
  simulationState: SimulationsState;
  svg: TrustedHTML;
}

const initialState: PocSnciState = {
  isOpen: false,
  stream: undefined,
  simulationState: SimulationsState.stopped,
  svg: undefined,
}

const pocSnciSlice = createSlice({
  name: 'poc-snci',
  initialState,
  reducers: {
    setPocSnciOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
    updateStream: (state, action: PayloadAction<SvgStream>) => {
      state.stream = action.payload
    },
    updateSimulationState: (state, action: PayloadAction<SimulationsState>) => {
      state.simulationState = action.payload
    },
    setSvg: (state, action: PayloadAction<TrustedHTML>) => {
      state.svg = action.payload
    },
  },
})

export const {
  setPocSnciOpen,
  updateStream: updatePocSnciStream,
  updateSimulationState,
  setSvg: setPocSnciSvg,
} = pocSnciSlice.actions

export default pocSnciSlice.reducer
