export const MAIN_API = {
  proxy: process.env.REACT_APP_GATEWAY_URL || '',
  api: process.env.REACT_APP_USE_LOCAL_BACKEND // deprecated
    ? `http://${window.location.hostname}:8000`
    : process.env.REACT_APP_GATEWAY_URL,
  http: process.env.REACT_APP_USE_LOCAL_BACKEND === 'true'
    ? `http://${process.env.REACT_APP_LOCAL_DOMAIN}`
    : `https://${process.env.REACT_APP_GATEWAY_DOMAIN}`,
  websocket: process.env.REACT_APP_USE_LOCAL_BACKEND === 'true'
    ? `ws://${process.env.REACT_APP_LOCAL_DOMAIN}`
    : `wss://${process.env.REACT_APP_GATEWAY_DOMAIN}`,
  version: '0.0.1.20200408.1',
}

export const PORTAL_URL = {
  url: process.env.REACT_APP_PORTAL_URL,
}

export const GATEWAY_DOMAIN = {
  url: process.env.REACT_APP_GATEWAY_DOMAIN,
}

export default MAIN_API
