import SvgWrapper from 'components/SvgWrapper'
import SimulationStream from 'components/simulationStream'
import { useAppDispatch, useAppSelector } from 'hooks'
import {
  MouseEvent, ReactElement, useEffect, useRef,
} from 'react'
import { useParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { RootState } from 'reducers/store'
import { updateSimulationState } from 'reducers/tpt/slices'
import { getTptSvg } from 'reducers/tpt/thunks'
import { UrlParams } from 'reducers/types'
import { isOldSimulation } from 'services/route'
import { BroadcastMessage } from 'types/tco'
import { SocketMessage } from 'types/websocket'

export default function Tpt(): ReactElement {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation, webSocket } = useAppSelector((state: RootState) => state.simulations)
  const { stream, svg } = useAppSelector((state: RootState) => state.tpt)

  const sendCloseMessage = () => {
    broadcastRef?.current.postMessage({ type: BroadcastMessage.closeTpt })
  }

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.postMessage({ type: BroadcastMessage.openTpt })

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.closeTpt) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateState) {
          dispatch(updateSimulationState(event.data.message))
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(setSelectedSimulation({
            ...selectedSimulation,
            name: event.data.message,
          }))
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id))
    }

    window.addEventListener('beforeunload', sendCloseMessage)

    return () => {
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  const handleClick = (evt: MouseEvent<HTMLElement>) => {
    const { id } = (evt.target as SVGRectElement)
    if (id === 'ProtectionTestButton:1:led') {
      webSocket.current.send(JSON.stringify({
        messageType: SocketMessage.interaction,
        name: id,
        interaction: 'TOGGLE',
      }))
    }
  }

  const clickEvent = isOldSimulation(urlParams) ? () => undefined : handleClick

  useEffect(() => {
    dispatch(getTptSvg())
  }, [])

  useEffect(() => {
    if (!stream || !svg) return
    Object.entries(stream).forEach(([key, value]) => {
      const element = document.getElementById(key)
      if (element) {
        try {
          // quick fix to remove glow on FF
          element.setAttribute('transform', 'translate(0, 0)')
          element.setAttribute(Object.keys(value)[0], Object.values(value)[0])
        } catch (e) {
          // console.log(e)
        }
      }
    })
  }, [stream, svg])

  return (
    <>
      <SvgWrapper svg={svg} handleClick={clickEvent} />
      <SimulationStream type="tpt-svg" />
    </>
  )
}
