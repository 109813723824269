import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import terms from 'assets/localize/terms'
import { useAppSelector } from 'hooks'
import { MouseEvent, ReactElement, useState } from 'react'
import ACCOUNT_MENU_ITEMS from './menuItem'

export default function AccountMenu(): ReactElement {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const user = useAppSelector(state => state.user)
  const isMenuOpen = Boolean(anchorEl)

  const handleButtonClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
  }

  const handleMenuClose = (next?: () => void) => {
    setAnchorEl(null)
    if (next !== undefined) next()
  }

  return (
    <div>
      <Button onClick={handleButtonClick} endIcon={<KeyboardArrowDownIcon />}>
        {`${user.account.firstName} ${user.account.lastName}`}
      </Button>
      <Menu open={isMenuOpen} anchorEl={anchorEl} onClose={() => handleMenuClose()}>
        {ACCOUNT_MENU_ITEMS.map(item => (item.show && (
          <MenuItem className="flex-center" key={item.label} onClick={() => handleMenuClose(item.onClick)}>
            {item.icon && (
            <div className="menu-item-icon flex-center" style={{ marginRight: '10px' }}>
              {item.icon}
            </div>
            )}
            <div className="menu-item-title">
              {terms.Common[item.label]}
            </div>
          </MenuItem>
        )))}
      </Menu>
    </div>
  )
}
