/* eslint-disable max-len */
import {
  get,
} from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import {
  setTptSvg,
} from './slices'

const getSvg = () => createApiThunk(
  'tpt/getSvg',
  () => get('/masters/static/interfaces/tpt'),
  setTptSvg,
)

export {
  getSvg as getTptSvg,
}
