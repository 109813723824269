import { useAppSelector } from 'hooks'
import { terms } from 'assets/localize/terms'
import './style.scss'

export default function Header() {
  const user = useAppSelector(state => state.user)
  return (
    <div className="header-page">
      {terms.Common.welcome}
      {' '}
      {`${user.account.firstName}`}
      {' '}
      !
    </div>
  )
}
