import SearchIcon from '@mui/icons-material/Search'
import './style.scss'

type Props = {
  searchQuery: string,
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>
}

function MessagesFilter({ searchQuery, setSearchQuery }: Props) {
  const searchFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value
    setSearchQuery(query)
  }

  return (
    <div className="message-filter">
      <input
        type="text"
        placeholder="Rechercher dans les logs"
        className="search"
        value={searchQuery}
        onChange={searchFilter}
      />
      <SearchIcon className="icon" />
    </div>
  )
}

export default MessagesFilter
