/* eslint-disable max-len */
import { post } from '@osrdata/app_core/dist/requests'
import { createApiThunk } from 'utils'
import { setUsersInfo } from './slices'

const getUsersInfo = (array: string[]) => createApiThunk(
  'userinfo/getUserInfo',
  () => post('/cerbere/users/simple', array),
  setUsersInfo,
)

export {
  getUsersInfo,
}
