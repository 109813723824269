import { modalSignal } from 'components/modals/wrapper'
import SimulationStream from 'components/simulationStream'
import { useAppDispatch, useAppSelector } from 'hooks'
import { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { setSelectedSimulation } from 'reducers/simulations/slices'
import { getSimulation } from 'reducers/simulations/thunks'
import { RootState } from 'reducers/store'
import { updateSimulationState } from 'reducers/tco/slices'
import { UrlParams } from 'reducers/types'
import { BroadcastMessage } from 'types/tco'

import SvgWrapper from 'components/SvgWrapper'
import { getTcoSvg } from 'reducers/tco/thunks'
import { isOldSimulation } from 'services/route'

export default function Tco() {
  const dispatch = useAppDispatch()
  const urlParams = useParams<UrlParams>()
  const broadcastRef = useRef<BroadcastChannel>()
  const { selectedSimulation } = useAppSelector((state: RootState) => state.simulations)
  const { stream, svg } = useAppSelector((state: RootState) => state.tco)

  const confirmClose = useCallback((e: Event) => {
    e.preventDefault()
  }, [])

  const sendCloseMessage = useCallback(() => {
    broadcastRef?.current.postMessage({ type: BroadcastMessage.closeTco })
  }, [])

  useEffect(() => {
    const state = urlParams.mode === 'static' ? 'on' : 'off'
    dispatch(getTcoSvg(state))
  }, [])

  useEffect(() => {
    if (urlParams.id && !broadcastRef.current) {
      const bc = new BroadcastChannel(urlParams.id)

      bc.postMessage({ type: BroadcastMessage.openTco })

      bc.onmessage = (event: MessageEvent) => {
        if (event.data.type === BroadcastMessage.closeTco) {
          window.close()
        }
        if (event.data.type === BroadcastMessage.updateState) {
          dispatch(updateSimulationState(event.data.message))
        }
        if (event.data.type === BroadcastMessage.updateName) {
          dispatch(setSelectedSimulation({
            ...selectedSimulation,
            name: event.data.message,
          }))
        }
      }

      broadcastRef.current = bc

      dispatch(getSimulation(urlParams.id))
    }

    if (isOldSimulation(urlParams)) {
      window.addEventListener('beforeunload', sendCloseMessage)
    } else {
      window.addEventListener('beforeunload', confirmClose)
      window.addEventListener('unload', sendCloseMessage)
    }

    return () => {
      window.removeEventListener('beforeunload', confirmClose)
      window.removeEventListener('unload', sendCloseMessage)
      window.removeEventListener('beforeunload', sendCloseMessage)
    }
  }, [selectedSimulation])

  useEffect(() => {
    if (isOldSimulation(urlParams)) return

    if (modalSignal.value) {
      window.addEventListener('beforeunload', sendCloseMessage)
      window.removeEventListener('beforeunload', confirmClose)
    } else {
      window.removeEventListener('beforeunload', sendCloseMessage)
      window.addEventListener('beforeunload', confirmClose)
    }
  }, [modalSignal.value])

  useEffect(() => {
    if (!stream || !svg) return
    Object.entries(stream).forEach(([key, value]) => {
      const element = document.getElementById(key)
      const properties = Object.keys(value)

      if (element) {
        properties.forEach(prop => {
          if (prop === 'innerHTML') {
            element.innerHTML = value[prop]
            return
          }
          element.setAttribute(prop, value[prop])
        })
      }
    })
  }, [stream, svg])

  return (
    <>
      <SvgWrapper svg={svg} />
      <SimulationStream type="tco-svg" />
    </>
  )
}
