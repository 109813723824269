import terms from 'assets/localize/terms'
import { setSnackbar, SnackbarSeverity } from 'reducers/app'

type Action = {
  type: string,
  error?: {
    message: string,
  }
  payload: {
    code: number,
    data: string[],
    type: string,
  }
}

type Next =
  (arg0: {
    payload: {
      code: number;
      data: string[]
      type: string
    }
    | {
      message: string;
      severity: SnackbarSeverity
    };
    type: string;
    error?: {
      message: string
    } | undefined
  }) => void

type ErrorInterceptorMiddleware = () => (next: Next) => (action: Action) => void
const errorInterceptorMiddleware: ErrorInterceptorMiddleware = () => (next: Next) => (action: Action) => {
  if (action.type.endsWith('/rejected')) {
    switch (action?.payload?.code) {
      case 400:
        next(setSnackbar({
          message: terms.Error.httpStatus.badRequest,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 403:
        next(setSnackbar({
          message: terms.Error.httpStatus.forbidden,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 404:
        next(setSnackbar({
          message: terms.Error.httpStatus.notFound,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 500:
        next(setSnackbar({
          message: terms.Error.httpStatus.internalServerError,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      case 502:
        next(setSnackbar({
          message: terms.Error.httpStatus.serverDown,
          severity: SnackbarSeverity.ERROR,
        }))
        break
      default:
        next(setSnackbar({
          message: terms.Error.httpStatus.unknownError,
          severity: SnackbarSeverity.ERROR,
        }))
    }
  }

  return next(action)
}

export default errorInterceptorMiddleware
