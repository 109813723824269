export enum SocketMessage {
  geojson = 'GEOJSON_PATCH',
  time = 'TIME',
  svg = 'SVG_PATCH',
  pause = 'PAUSE',
  stop = 'STOP',
  start = 'START',
  interaction = 'INTERACTION',
  speed = 'SPEED',
  keyPress = 'KEY_PRESS',
  clearLogs = 'CLEAR_LOGS'
}
